import api from './api';

class AdminService {

  getAllUsers(div) {
    return api.get('secure/admin/users/' + div);
  }

  updateUser(user) {
    let cardscheck = user.cards;
    if (cardscheck === 3) {
      cardscheck = null;
    }
    return api.put('/secure/admin/users', {
      id: user.id,
      paid: user.paid,
      teamcaptain: user.teamcaptain,
      cards: cardscheck,
      notes: user.notes
    });
  }
  signInUser(user) {
    return api.put('/secure/admin/signin', {
      id: user.id
    });
  }

}

export default new AdminService();