<template>
  <v-main>
    <v-app-bar
      class="ukpl-main-navigation"
      v-if="currentUser"
      dense
      color="#0072ff"
      dark
      elevate-on-scroll
    >
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img
              src="@/assets/ukpl-logo-new.png"
              alt="UKPL logo"
              contain
              class="ukpl-nav-logo"
            />
          </a>
        </div>
        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <!-- <v-btn color="#0072ff" depressed to="/home"
                        ><v-icon class="mr-2">fa-home</v-icon>Home</v-btn
                      > -->
          <v-btn v-if="isAdmin" color="#0072ff" depressed to="/admin"
            >admin</v-btn
          >
          <v-btn color="#0072ff" depressed to="/team"
            ><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn
          >
          <v-btn v-if="currentCaptain" color="#0072ff" depressed to="/roster"
            ><v-icon class="mr-2">fa-list</v-icon>Roster</v-btn
          >
          <v-btn color="#0072ff" depressed to="/profile"
            ><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn
          >
          <v-btn color="#0072ff" depressed to="/disclaimer"
            ><v-icon class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn
          >
          <v-btn color="#0072ff" depressed to="/idcard"
            ><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn
          >
          <v-btn color="#0072ff" depressed @click.native="logOut"
            ><v-icon class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn
          >
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon
              ><v-icon>fa-file-signature</v-icon></v-list-item-icon
            >
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon
              ><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon
            >
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-sheet
      id="scrolling-techniques-7"
      class="ukpl-admin-content-container"
      height="100%"
    >
      <v-container v-if="loading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12"> Loading </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-progress-linear
              color="blue"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!loading" fluid height="100%">
        <v-row justify="center" align="center" class="row">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <div class="ukpl-h2-heading-wrap">
              <h2 class="ukpl-h2-heading">Division Select</h2>
            </div>
            <v-select
              ref="divselect"
              v-model="selectdiv"
              :items="divisions"
              item-text="name"
              item-value="id"
              label="Division"
              dense
              v-on:change="loadTable"
            ></v-select>
          </v-col>
        </v-row>

        <v-row justify="center" align="center" class="row">
          <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="-1"
              :loading="loading"
              sort-by="['division', 'team']"
              class="elevation-1"
              item-key="id"
              @click:row="editItem"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>List Users</v-toolbar-title>
                </v-toolbar>

                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">Edit Player</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.firstname"
                              disabled
                              label="First name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.lastname"
                              disabled
                              label="Last Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.division"
                              disabled
                              label="Division"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.team"
                              disabled
                              label="Team"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-chip
                              v-if="editedItem.rostered"
                              disabled
                              color="green"
                              >On Roster<v-icon right
                                >mdi-check-circle</v-icon
                              ></v-chip
                            >
                            <v-chip v-else disabled color="red"
                              >On Roster<v-icon right
                                >mdi-close-circle</v-icon
                              ></v-chip
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-chip
                              v-if="editedItem.signedin"
                              disabled
                              color="green"
                              >Signed In<v-icon right
                                >mdi-check-circle</v-icon
                              ></v-chip
                            >
                            <v-chip v-else color="red"
                            @click="signin()"
                              >Signed In<v-icon right
                                >mdi-close-circle</v-icon
                              ></v-chip
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-chip
                              v-if="editedItem.paid"
                              color="green"
                              @click="editedItem.paid = false"
                              >Paid<v-icon right
                                >mdi-check-circle</v-icon
                              ></v-chip
                            >
                            <v-chip
                              v-else
                              color="red"
                              @click="editedItem.paid = true"
                              >Paid<v-icon right
                                >mdi-close-circle</v-icon
                              ></v-chip
                            >
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-chip
                              v-if="editedItem.teamcaptain"
                              color="green"
                              @click="editedItem.teamcaptain = false"
                              >Team Captain<v-icon right
                                >mdi-check-circle</v-icon
                              ></v-chip
                            >
                            <v-chip
                              v-else
                              color="red"
                              @click="editedItem.teamcaptain = true"
                              >Team Captain<v-icon right
                                >mdi-close-circle</v-icon
                              ></v-chip
                            >
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-label>Cards</v-label>
                            <v-radio-group v-model="editedItem.cards" column>
                              <v-radio
                                off-icon="mdi-tag-outline"
                                on-icon="mdi-tag"
                                label="Red"
                                color="red"
                                value="RED"
                              ></v-radio>
                              <v-radio
                                off-icon="mdi-tag-outline"
                                on-icon="mdi-tag"
                                label="Yellow"
                                color="yellow"
                                value="YELLOW"
                              ></v-radio>
                              <v-radio
                                off-icon="mdi-tag-multiple-outline"
                                on-icon="mdi-tag-multiple"
                                label="Red AND Yellow"
                                color="red"
                                value="RED_YELLOW"
                              ></v-radio>
                              <v-radio
                                off-icon="mdi-tag-remove-outline"
                                on-icon="mdi-tag-remove"
                                label="No Cards"
                                value=""
                              ></v-radio>
                            </v-radio-group>
                          </v-col>
                          <v-col cols="6">
                            <v-img
                              :src="generateFileUrl(editedItem.photo)"
                              max-width="100"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              label="Player Notes"
                              v-model="editedItem.notes"
                              clearable
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.teamcaptain="{ item }">
                <v-icon v-if="item.teamcaptain" color="green"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.rostered="{ item }">
                <v-icon v-if="item.rostered" color="green"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.signedin="{ item }">
                <v-icon v-if="item.signedin" color="green"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.notes="{ item }">
                <v-icon v-if="item.notes" color="green"
                  >mdi-check-circle</v-icon
                >
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.cards="{ item }">
                <div v-if="item.cards === 'RED'">
                  <v-icon color="red">mdi-tag</v-icon>
                </div>
                <div v-if="item.cards === 'YELLOW'">
                  <v-icon color="yellow">mdi-tag</v-icon>
                </div>
                <div v-if="item.cards === 'RED_YELLOW'">
                  <v-icon color="red">mdi-tag</v-icon>
                  <v-icon color="yellow">mdi-tag</v-icon>
                </div>
              </template>
              <template v-slot:item.paid="{ item }">
                <v-icon v-if="item.paid" color="green">mdi-check-circle</v-icon>
                <v-icon v-else color="red">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.photo="{ item }">
                <v-img :src="generateFileUrl(item.photo)" max-width="40" />
              </template>
              <!-- <template v-slot:item.photo="props">
                <v-edit-dialog>
                  <v-img :src="generateFileUrl(props.item.photoThumbnail)" max-width="40" />
                  <template v-slot:input>
                    <v-img :src="generateFileUrl(props.item.photoThumbnail)" max-width="400" />
                  </template>
                </v-edit-dialog>
              </template> -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import AdminService from "../services/admin.service";
import DivisionService from "../services/division.service";
import adminService from "../services/admin.service";

export default {
  name: "Team",
  components: {},
  data: () => ({
    bucket: "https://assets.ukpl.co.uk/",
    photobase: "images/",
    thumbbase: "thumbs/images/",
    divisions: [],
    selectdiv: "1",
    currentCaptain: false,
    drawer: false,
    dialog: false,
    loading: true,
    editedIndex: -1,
    editedItem: {
      id: -1,
      firstname: "",
      lastname: "",
      division: "",
      team: "",
      paid: false,
      teamcaptain: false,
      rostered: false,
      signedin: false,
      cards: "",
      notes: "",
      photo: null,
      photoThumbnail: "",
    },
    defaultItem: {
      id: -1,
      firstname: "",
      lastname: "",
      division: "",
      team: "",
      paid: false,
      teamcaptain: false,
      rostered: false,
      signedin: false,
      cards: "",
      notes: "",
      photo: null,
      photoThumbnail: "",
    },
    headers: [
      { text: "#", align: "start", value: "id" },
      { text: "FirstName", value: "firstname" },
      { text: "LastName", value: "lastname" },
      { text: "Div", value: "division" },
      { text: "Team", value: "team" },
      { text: "Paid", value: "paid" },
      { text: "Captain", value: "teamcaptain" },
      { text: "Rostered", value: "rostered" },
      { text: "Signed In", value: "signedin" },
      { text: "Cards", value: "cards" },
      { text: "Notes", value: "notes" },
      { text: "photo", value: "photo" },
    ],
    users: undefined,
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
  },
  methods: {
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    initializeDiv() {
      DivisionService.getDivisions().then(
        (response) => {
          this.divisions = response.data;
          this.divisions.push({ id: -1, division: "null", name: "none" });
          this.selectdiv = this.divisions[0].id;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    generateFileUrl(photo) {
      if (photo === undefined || photo === null) {
        return "";
      } else {
        let filename = this.bucket + this.thumbbase + photo.id;
        if (photo.type === "image/jpeg") {
          filename = filename + ".jpg";
        } else if (photo.type === "image/png") {
          filename = filename + ".png";
        } else {
          filename = "";
        }
        return filename;
      }
    },
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
    loadTable() {
      this.loading = true;
      AdminService.getAllUsers(this.selectdiv).then(
        (response) => {
          this.users = response.data;
          this.loading = false;
        },
        (error) => {
          console.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
          this.loading = false;
        }
      );
    },
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      Object.assign(this.users[this.editedIndex], this.editedItem);
      console.log(this.editedItem);
      adminService.updateUser(this.editedItem).then(
        () => null,
        (error) => {
          console.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
          this.loading = false;
        }
      );
      this.close();
    },
    signin() {
      this.editedItem.signedin = true;
      Object.assign(this.users[this.editedIndex], this.editedItem);
      adminService.signInUser(this.editedItem).then(
        () => null,
        (error) => {
          console.error(
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString()
          );
          this.loading = false;
        }
      );
    }
  },
  created() {
    this.initializeDiv();
    this.loadTable();
    this.isCaptain();
  },
  updated() {
    this.loading = false;
  },
  mounted() {},
};
</script>
